import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | MotoMystique
			</title>
			<meta name={"description"} content={"Unveiling MotoMystique"} />
			<meta property={"og:title"} content={"About | MotoMystique"} />
			<meta property={"og:description"} content={"Unveiling MotoMystique"} />
			<meta property={"og:image"} content={"https://liltokoy.com/img/bike_2.jpg"} />
			<link rel={"shortcut icon"} href={"https://liltokoy.com/img/3824290.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://liltokoy.com/img/3824290.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://liltokoy.com/img/3824290.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://liltokoy.com/img/3824290.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://liltokoy.com/img/3824290.png"} />
			<meta name={"msapplication-TileImage"} content={"https://liltokoy.com/img/3824290.png"} />
			<meta name={"msapplication-TileColor"} content={"https://liltokoy.com/img/3824290.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 180px 0" lg-padding="90px 0 90px 0" sm-padding="45px 0 60px 0" quarkly-title="About-15">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" sm-min-width="280px" />
			<Box
				width="20%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				justify-content="space-between"
				lg-width="100%"
				lg-align-items="stretch"
				lg-flex-direction="row"
				lg-justify-content="flex-start"
				md-flex-direction="column"
			>
				<Box
					lg-width="80%"
					lg-display="flex"
					lg-flex-direction="column"
					lg-align-items="flex-start"
					lg-justify-content="flex-end"
					md-width="100%"
					height="100%"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="space-around"
				>
					<Text
						margin="0px 0px 12px 0px"
						font="--base"
						text-transform="uppercase"
						color="--primary"
						letter-spacing="1px"
					>
						About Us
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						font="--headline2"
						color="--dark"
						lg-text-align="left"
						md-padding="0px 0px 0px 0px"
					>
						About MotoMystique{"\n\n"}
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						font="--base"
						color="--greyD2"
						md-text-align="left"
						lg-flex="1 1 0%"
						lg-padding="0px 0px 0px 0px"
						lg-margin="0px 0px 32px 0px"
						sm-margin="0px 0px 28px 0px"
					>
						MotoMystique isn't just a name, it's a sentiment, an emotion, and an embodiment of the free spirit that lives in every rider's heart. It's an entity that brings together enthusiasts, learners, and seasoned riders under one roof, promising a motorcycling experience that stands out from the norm.{"\n\n"}
					</Text>
					<Link
						href="/contact"
						color="--lightD1"
						text-decoration-line="initial"
						padding="7px 24px 8px 24px"
						background="--color-primary"
						font="--base"
						transition="--opacityOut"
						hover-opacity=".7"
						letter-spacing="1px"
						border-radius="4px"
					>
						Order first lesson
					</Link>
				</Box>
			</Box>
			<Box
				width="80%"
				display="flex"
				justify-content="flex-end"
				lg-width="100%"
				lg-margin="48px 0px 0px 0px"
				lg-flex-direction="column"
			>
				<Box position="relative">
					<Image
						src="https://liltokoy.com/img/bike_3.jpg"
						display="block"
						object-fit="cover"
						position="absolute"
						height="250px"
						width="300px"
						left="-120px"
						bottom="-120px"
						lg-position="static"
						lg-width="100%"
						lg-height="auto"
					/>
					<Image
						src="https://liltokoy.com/img/client_3.jpg"
						display="block"
						object-fit="cover"
						height="380px"
						width="550px"
						lg-width="100%"
						lg-height="auto"
						lg-margin="8px 0px 0px 0px"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-10">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="flex"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
			/>
			<Box min-width="100px" min-height="100px" margin="0px 0px 16px 0px">
				<Text margin="0px 0px 10px 0px" font="normal 400 18px/1.5 --fontFamily-sans" color="--primary">
					Introducing
				</Text>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Our Motorcycle Selection Philosophy{"\n\n"}
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px 54px"
					margin="0px 0px 25px 0px"
					md-grid-template-columns="1fr"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="#505257">
							We believe in quality over quantity. Each motorcycle in our collection is carefully chosen, ensuring a diverse range of experiences for our clients. From bikes that roar with power to those that offer a more relaxed city cruise, our collection is curated to satiate every rider's craving. Each bike isn't just a mode of transport, it's an adventure waiting to be explored.{"\n\n"}
						</Text>
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
							Motorcycling isn't just about throttling and steering, it's an art. And like every art form, it demands dedication, patience, and the right guidance. At MotoMystique School:{"\n"}
							<br />
							• We prioritise safety and instill it as a primary value in every rider.{"\n"}
							<br />
							• Our instructors are seasoned riders, passionate about sharing their knowledge and skills.{"\n"}
							<br />
							• We offer tailored lesson plans, catering to the individual needs and pace of every student.{"\n\n"}
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
							<Strong>
								{"\n"}Why Choose MotoMystique?{"\n"}
							</Strong>
							<br />
							Customer-Centric Approach - We put our riders and students at the forefront, ensuring every experience is memorable.{"\n"}
							<br />
							Flexible Options - Whether you want to rent a bike for a day, a week, or even longer, or wish to take lessons at your convenience, we've got you covered.{"\n"}
							<br />
							Transparent Pricing - No hidden costs. What you see is what you get.
Continuous Evolution - We are constantly updating our bike collection and school curriculum, ensuring we stay ahead in offering the best.{"\n\n"}
						</Text>
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
							Thank you for showing interest in MotoMystique. We await the opportunity to be a part of your motorcycling journey. Reach Out to us for any further information or inquiries.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Frequently asked questions
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Navigating the realm of motorcycle rentals and training can evoke numerous questions. At MotoMystique, we believe in clarity and transparency. Here's a compilation of the most frequently asked questions to assist you on your journey with us. If you don't find the answer here, don't hesitate to reach out.{"\n\n"}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						1. What types of motorcycles do you offer for rent?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						We offer a diverse range of motorcycles tailored for different experiences - from adventure seekers for rugged terrains to speed spectres for the adrenaline junkies, and urban unwind bikes for comfortable city escapades.{"\n\n"}
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						2. I'm a beginner. Can I still rent a motorcycle?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Yes, you can, as long as you have appropriate drivers license! However, we recommend our beginner course at MotoMystique School to ensure you're comfortable and safe on the road. We prioritise the safety of our clients and encourage new riders to get trained before venturing out.{"\n\n"}
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						3. What safety measures are in place for the motorcycles?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Every motorcycle undergoes rigorous maintenance and safety checks before being rented out. We also provide comprehensive insurance coverage for all our rentals, ensuring peace of mind for our riders.{"\n\n"}
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						4. How long is the duration of the beginner's course at the MotoMystique School?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Our beginner's course typically spans several sessions over a week, but the duration can be customised based on individual pace and requirements.{"\n\n"}
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						5. Can I join the MotoMystique community events even if I haven't rented a bike or enrolled in the school?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Absolutely! Our community events, ride-along sessions, and workshops are open for all motorcycling enthusiasts. You're welcome to join and be a part of the MotoMystique family.{"\n\n\n\n"}
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						6. Are there any age restrictions for renting or enrolling in the school?{"\n\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Riders must be of legal age as per local regulations to rent a motorcycle. Similarly, for enrolling in the MotoMystique School, we adhere to the local age requirements for motorcycle licensing.{"\n\n"}
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});